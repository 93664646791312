import PropTypes from 'prop-types';

import { tileShape } from './tile';

const imageShape = PropTypes.shape({
  url: PropTypes.string,
});

export const agendaItemShape = PropTypes.shape({
  dateTime: PropTypes.string,
  notes: PropTypes.shape({
    notes: PropTypes.string,
  }),
  title: PropTypes.string,
});

export const descriptionShape = PropTypes.shape({
  description: PropTypes.string,
});

export const unitqVipShape = PropTypes.shape({
  description: descriptionShape,
  id: PropTypes.string,
  image: imageShape,
  name: PropTypes.string,
  role: PropTypes.string,
});

export const featuredEventShape = PropTypes.shape({
  city: PropTypes.string,
  dateTime: PropTypes.string,
  id: PropTypes.string,
  image: imageShape,
  slug: PropTypes.string,
  src: PropTypes.string,
  venue: PropTypes.string,
});

export const eventShape = PropTypes.shape({
  address: PropTypes.string,
  agenda: PropTypes.arrayOf(agendaItemShape),
  ctaText: PropTypes.string,
  city: PropTypes.string,
  dateTime: PropTypes.string,
  description: descriptionShape,
  featuredEvents: PropTypes.arrayOf(featuredEventShape),
  googleMapsUrl: PropTypes.string,
  hubspotFormId: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string,
  }),
  meetAndGreet: PropTypes.arrayOf(unitqVipShape),
  slug: PropTypes.string,
  speakers: PropTypes.arrayOf(unitqVipShape),
  type: PropTypes.string,
  title: PropTypes.string,
  venue: PropTypes.string,
  whatToExpect: PropTypes.arrayOf(tileShape),
});
