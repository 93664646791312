import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { UqLink } from '../../link';

import * as styles from './spotlight.module.scss';

UqNavSpotlightV2.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  href: PropTypes.string,
  title: PropTypes.string,
};

export function UqNavSpotlightV2(props) {
  return (
    <div className={styles.root}>
      <div className={classNames('caption-1', styles.title)}>
        {props.title}
      </div>
      <UqLink
        as="a"
        className={styles.link}
        to={props.href}
        external={true}
        hideArrow
      >
        <div className={styles.content}>
          {props.children}
        </div>
      </UqLink>
    </div>
  );
}
