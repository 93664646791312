// extracted by mini-css-extract-plugin
export var about = "nav-icon-link-module--about--cca5c";
export var blog = "nav-icon-link-module--blog--38278";
export var careers = "nav-icon-link-module--careers--3f98f";
export var contact = "nav-icon-link-module--contact--d9adc";
export var cx = "nav-icon-link-module--cx--03e6b";
export var dating = "nav-icon-link-module--dating--b664f";
export var elearning = "nav-icon-link-module--elearning--b04d1";
export var engineering = "nav-icon-link-module--engineering--446ad";
export var events = "nav-icon-link-module--events--e62e6";
export var finance = "nav-icon-link-module--finance--45ad4";
export var food = "nav-icon-link-module--food--8ae63";
export var gaming = "nav-icon-link-module--gaming--1f556";
export var gpt = "nav-icon-link-module--gpt--7ab4e";
export var healthandfitness = "nav-icon-link-module--healthandfitness--0bd8b";
export var help = "nav-icon-link-module--help--d845e";
export var integrations = "nav-icon-link-module--integrations--6d55a";
export var leadership = "nav-icon-link-module--leadership--319ec";
export var link = "nav-icon-link-module--link--dc37b";
export var marketing = "nav-icon-link-module--marketing--aeac3";
export var music = "nav-icon-link-module--music--2d4ec";
export var news = "nav-icon-link-module--news--165c8";
export var overview = "nav-icon-link-module--overview--fa216";
export var product = "nav-icon-link-module--product--c299a";
export var scorecards = "nav-icon-link-module--scorecards--f0911";
export var shopping = "nav-icon-link-module--shopping--ea825";
export var topapps = "nav-icon-link-module--topapps--48b40";
export var travel = "nav-icon-link-module--travel--28705";
export var video = "nav-icon-link-module--video--adca5";
export var whyquality = "nav-icon-link-module--whyquality--62bbd";