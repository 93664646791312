import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { UqGptCta } from '../../gpt';
import { Freya, UqNavSpotlightV2 } from './desktop';

export const Content = {
  items: [
    {
      expansion: (
        <UqGptCta />
      ),
      label: 'Product',
      links: [
        { label: 'Overview', href: '/product', id: 'overview' },
        { label: 'unitQ GPT', href: '/unitq-gpt', id: 'gpt' },
        { label: 'Integrations', href: '/integrations?filter=all', id: 'integrations' },
        // @todo [epanulla] Reinstate when the design will be implemented
        /* { label: 'Why Quality?', href: '/product-quality/', id: 'whyquality' }, */
      ],
      spotlight: (
        <UqNavSpotlightV2
          href="https://get.unitq.com/unitq-gpt-pdf"
          title="unitQ GPT Guide"
        >
          <StaticImage
            style={{ maxWidth: '148px' }}
            src="../../../../images/navigation/uq-gpt-spotlight.png"
            alt="unitQ GPT Guide Download"
          />
        </UqNavSpotlightV2>
      ),
    },
    {
      cta: {
        title: 'One source for all teams.',
        description: 'Empower everyone in your organization with real-time user insights to unlock growth.',
      },
      label: 'Solutions',
      links: [
        { label: 'CX', href: '/solutions/support', id: 'cx' },
        { label: 'Engineering', href: '/solutions/engineering', id: 'engineering' },
        { label: 'Leadership', href: '/solutions/leadership', id: 'leadership' },
        { label: 'Product', href: '/solutions/product-operations', id: 'product' },
        { label: 'Marketing', href: '/solutions/marketing', id: 'marketing' },
      ],
      titles: {
        links: 'Explore by Team',
      },
      variant: 'grid',
    },
    {
      cta: {
        title: 'What is the unitQ Score?',
        description: 'The unitQ Score is a measure of your product\'s quality based on public feedback sources.',
      },
      label: 'unitQ Score',
      links: [
        // Items are a-z by column, but CSS grid lays them out by row
        { label: 'Top Apps', href: '/unitqscore/?category=top-apps', id: 'topapps' },
        { label: 'Food', href: '/unitqscore/?category=food', id: 'food' },
        { label: 'Shopping', href: '/unitqscore/?category=shopping', id: 'shopping' },

        { label: 'Dating', href: '/unitqscore/?category=dating', id: 'dating' },
        { label: 'Gaming', href: '/unitqscore/?category=gaming', id: 'gaming' },
        { label: 'Travel', href: '/unitqscore/?category=travel', id: 'travel' },

        { label: 'E-Learning', href: '/unitqscore/?category=e-learning', id: 'elearning' },
        { label: 'Health & Fitness', href: '/unitqscore/?category=health-and-fitness', id: 'healthandfitness' },
        { label: 'Video', href: '/unitqscore/?category=video', id: 'video' },

        { label: 'Finance', href: '/unitqscore/?category=finance', id: 'finance' },
        { label: 'Music', href: '/unitqscore/?category=music', id: 'music' },
        { label: 'Scorecard List', href: '/unitq-scorecards', id: 'scorecards' },
      ],
      titles: {
        links: 'Explore by Vertical',
      },
      variant: 'grid',
    },
    {
      label: 'Customers',
      href: '/customers',
    },
    {
      expansion: (
        <Freya />
      ),
      label: 'Company',
      links: [
        { label: 'About', href: '/about-us', id: 'about' },
        { label: 'News', href: '/news', id: 'news' },
        { label: 'Careers', href: '/careers', id: 'careers' },
        { label: 'Contact', href: '/contactus', id: 'contact' },
      ],
    },
    {
      label: 'Resources',
      links: [
        { label: 'Blog', href: '/blog', id: 'blog' },
        // TODO: [epanulla] Icon was missing in mockup, use as temporary until Kira can provide
        { label: 'Library', href: '/resources', id: 'scorecards' },
        { label: 'Help Center', external: true, href: 'https://help.unitq.com/hc/en-us', id: 'help' },
      ],
    },
  ],
};
