import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage as getGatsbyImage } from 'gatsby-plugin-image';

import { isExternalUrl } from '../../../helpers/v2';
import { useHover } from '../../../hooks/v2';
import { UqLink } from '../navigation';
import { ActionLabelsMap, TypeLabelsMap } from './content';

import * as styles from './resource-card.module.scss';

UqResourceCard.propTypes = {
  createdDate: PropTypes.string,
  dark: PropTypes.bool,
  description: PropTypes.string,
  /** Fills the width of the container, using a horizontal layout on Desktop */
  fill: PropTypes.bool,
  loadStyle: PropTypes.oneOf(['eager', 'lazy']),
  thumbnail: PropTypes.shape({
    gatsbyImageData: PropTypes.shape(),
    url: PropTypes.string,
  }),
  title: PropTypes.string,
  type: PropTypes.oneOfType([
    PropTypes.oneOf([
      'blog',
      'e-book',
      'event',
      'podcast',
      'webinar',
    ]),
    PropTypes.string,
  ]),
  url: PropTypes.string,
};

UqResourceCard.defaultProps = {
  dark: false,
  imageVariant: 'default',
  variant: 'spotlight',
};

export function UqResourceCard(props) {
  const { isHovered, hoverTriggers } = useHover();

  return (
    <article
      className={classNames(styles.root, {
        [styles.dark]: props.dark,
        [styles.fill]: props.fill,
      })}
      {...hoverTriggers}
    >
      <UqLink
        className={classNames(styles.link, { [styles.fill]: props.fill })}
        to={props.url}
        external={isExternalUrl(props.url)}
        hideArrow
        variant="inherit"
      >
        <section className={styles.imageContainer}>
          <div
            className={styles.background}
            style={{ background: `url('${props.thumbnail.url}')` }}
          />
          <GatsbyImage
            alt={props.title}
            image={getGatsbyImage(props.thumbnail)}
            src={props.thumbnail.url}
            loading={props.loadStyle || 'lazy'}
          />
        </section>
        <section className={styles.content}>
          <div>
            <section className={classNames(styles.info, 'caption-1')}>
              <span>{TypeLabelsMap.get(props.type) || props.type}</span>
              {props.createdDate && (
                <>
                  <div className={styles.divider} />
                  <span>{props.createdDate}</span>
                </>
              )}
            </section>
            <h4 className={styles.title}>
              {props.title}
            </h4>
            {props.description && (
              <p className={classNames(styles.description, 'body-2')}>
                {props.description}
              </p>
            )}
          </div>

          <span className={classNames(styles.label, { [styles.active]: isHovered })}>
            {ActionLabelsMap.get(props.type) || 'Read Now'}
            <UqLink.Arrow active={isHovered} />
          </span>
        </section>
      </UqLink>
    </article>
  );
}
